<template>
  <div class="global-searchbar-detail">
    <div class="content-header-detail-global">
      <div class="content-text-title">
        <v-icon class="global-icon-arrow-back" @click="back()"
          >mdi-arrow-left</v-icon
        >
        <div class="text-detail-dynamic">
          <div class="text-detail">
            <p class="text-title">{{ sTextDetail }} {{ " " + sNameDetail }}</p>
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div v-show="setShowBtnCancel()" class="content-btns">
        <div class="content-btn-add">
          <v-btn
            v-if="bCreated"
            v-show="bAdminPermissions"
            @click="setDialogConfirmCancel()"
            :class="sClassBtnAdd"
            class="btn-add-global"
          >
            <span>{{ sTextBtnAdd }}</span>
          </v-btn>
        </div>
      </div>
      <div
        class="content-account-balance"
        v-show="setShowSectionAccountBalance()"
      >
        <div class="content-icon-currency">
          <v-avatar color="var(--primary-color-text-yellow)" size="40">
            <span>
              <v-icon class="text-icon-currency">mdi-currency-usd</v-icon>
            </span>
          </v-avatar>
        </div>
        <div class="content-text-currency">
          <p class="text-title-account-balance">Balance de cuenta</p>
          <p
            class="text-value-account-balance"
            :class="dAccountBalance > 0 ? 'class-in' : 'class-out'"
          >
            <span>{{ dAccountBalance > 0 ? "" : "" }}</span> $
            {{ formatMoneyGlobal(dAccountBalance) }} {{ sCurrencyType }}
          </p>
        </div>
      </div>

      <!-- #region ESTADO DE OPERACIÓN (DETALLE OPERACIONES) & MARCAR COMO PAGADA (DETALLE OPERACIONES CAJA)  -->
      <div v-if="setShowBtnAdd()" class="content-status-operations">
        <div class="content-status-date">
          <div class="content-text-state-date">
            <p class="text-title-global mb-0">Fecha de última modificación</p>
            <p class="text-information-global mb-0">
              {{
                ObjEmptyGlobal(oItem) ? oItem.oOrderStatus.tLastStatusChang : ""
              }}
            </p>
            <span class="text-date-operation">
              {{
                ObjEmptyGlobal(oItem)
                  ? oItem.oOrderStatus.tLastStatusChange
                  : ""
              }}
            </span>
          </div>
        </div>
        <v-divider vertical class="divider-global ml-1 mr-4"></v-divider>
        <div v-if="bShowStatusOrder && !bShowBtnMarkDone">
          <p class="text-title-global mb-0">Estado</p>
          <p class="text-information-global mb-0">
            {{ ObjEmptyGlobal(oItem) ? oItem.oOrderStatus.sName : "" }}
          </p>
          <!-- <v-btn v-if="bOPGeneralDetail || bOPPendingDetail" text class="btn-status-operation">
            {{ ObjEmptyGlobal(oItem) ? oItem.oOrderStatus.sName : "" }}
          </v-btn> -->
        </div>

        <v-btn
          v-if="(bOPGeneralDetail || bOPPayableDetail) && bShowBtnMarkDone"
          v-show="bAdminPermissions"
          @click="setDialogAdd()"
          :class="sClassBtnAdd"
          class="btn-add-global"
        >
          <span>{{ sTextBtnAdd }}</span>
        </v-btn>
      </div>
      <!-- #endregion ESTADO DE OPERACIÓN  -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sTextDetail: String,
    sBackHref: String,
    oParams: Object,
    bAdminPermissions: Boolean,
    setDialog: String,
    sTextBtnAdd: String,
    sClassBtnAdd: String,
    bWithPathOrNameRoute: {
      type: Boolean,
      default: false,
    },
    dAccountBalance: Number,
    sCurrencyType: String,
    toVieworModal: { type: Boolean, default: false },
    oItem: Object,
  },
  data() {
    return {
      aShowBtnCancel: ["OPGeneralDetail"],
      aShowBtnAdd: [
        "OPGeneralDetail",
        "OPPendingDetail",
        "OPPayableDetail",
        "OPDetailCustomer",
        "OPDetailProvider",
        "OperationDetails",
        "OPTransationDetailAccount",
        "OPDetailTransactionDetail",
      ],
      aStatusOrder: [
        "OPGeneralDetail",
        "OPPendingDetail",
        "OPPayableDetail",
        "OPDetailProvider",
        "OPDetailCustomer",
        "OperationDetails",
        "OPTransationDetailAccount",
        "OPDetailTransactionDetail",
      ],
      aShowSectionAccountBalance: ["AccountDetail"],
      aContentStatus: [
        {
          id: 1,
          text: "En proceso",
          icon: "mdi-currency-usd",
          class: "mr-2",
          color: "var(--primary-color-btn-primary)",
          totalOC: 24,
        },
      ],
      oItemOrigin: {},
      bCreated: false,
      aOPPaid: ["FINALIZADA"],
      bOPPaid: false,
      bShowBtnMarkDone: false,
    };
  },
  beforeMount() {
    this.getItem();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
  },
  methods: {
    back() {
      this.$router
        .push(
          this.bWithPathOrNameRoute
            ? this.sBackHref
            : {
                name: this.sBackHref,
              }
        )
        .catch(() => {});
    },
    setShowBtnAdd() {
      return this.aShowBtnAdd.filter((e) => e === this.$route.name).length > 0;
    },
    setShowBtnCancel() {
      return (
        this.aShowBtnCancel.filter((e) => e === this.$route.name).length > 0
      );
    },
    setShowSectionAccountBalance() {
      return (
        this.aShowSectionAccountBalance.filter((e) => e === this.$route.name)
          .length > 0
      );
    },
    setDialogAdd() {
      this.$emit("setDialogAdd");
    },
    setDialogConfirmCancel() {
      let oItem = {
        active: true,
        textTitleActionConfirm: "Cancelar órden de compra",
        textDescriptionActionConfirm:
          "La cancelación de una orden de compra es una acción irreversible. ",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con la cancelación de la orden de compra: ${this.oItem.sReference} `,
        returnToViewActionConfirm: false,
        apiActionConfirm: `${URI}/api/sp/v1/purchase-orders/general/${this.$route.params.idOC}/`,
        iTypeAction: 4, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: {},
      };
      this.$emit("setDialogConfirmCancel", oItem);
    },
    getItem() {
      this.oItemOrigin = this.oItem;
      if (this.bOPPayableDetail) {
        this.getOPPaid();
      }
    },
    getOPPaid() {
      if (this.ObjEmptyGlobal(this.oItem)) {
        this.bOPPaid =
          this.aOPPaid.filter((e) => e === this.oItem.oOrderStatus.sName)
            .length > 0;
      }
    },
  },
  computed: {
    sNameDetail() {
      return this.$store.state.sNameDetail;
    },
    bOPGeneralDetail() {
      return this.$route.name === "OPGeneralDetail";
    },
    bOPPendingDetail() {
      return this.$route.name === "OPPendingDetail";
    },
    bOPPayableDetail() {
      return this.$route.name === "OPPayableDetail";
    },
    bOperationDetails() {
      return this.$route.name === "OperationDetails";
    },
    bOPTransationDetailAccount() {
      return this.$route.name === "OPTransationDetailAccount";
    },
    bOPDetailTransactionDetail() {
      return this.$route.name === "OPDetailTransactionDetail";
    },
    bShowStatusOrder() {
      return this.aStatusOrder.filter((e) => e === this.$route.name).length > 0;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refreshTable() {
      this.getItem();
    },
    oItem() {
      if (this.bOPPayableDetail || this.bOPGeneralDetail) {
        // "ea3b1fd9-069b-45c0-820a-73be26fe7892"-> CREADA
        // "449fa03c-580f-4ef0-9674-8bcc85f8b0f3" -> Peso asignado
        if (
          this.oItem.oOrderStatus.sStatusId ===
            "ea3b1fd9-069b-45c0-820a-73be26fe7892" ||
          this.oItem.oOrderStatus.sStatusId ===
            "449fa03c-580f-4ef0-9674-8bcc85f8b0f3" ||
          this.oItem.oOrderStatus.sStatusId ===
            "6f2e4b24-2dec-4fa2-a1bb-d1f1f741b492"
        ) {
          this.bShowBtnMarkDone = true;
        } else {
          this.bShowBtnMarkDone = false;
        }

        this.getOPPaid();
      }
    },
  },
};
</script>
<style scoped>
.content-header-detail-global {
  display: flex;
  align-content: center;
  width: 100%;
}

.content-text-title {
  display: flex;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 28px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.text-detail {
  display: flex;
  align-items: center;
}

.text-dynamic {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 28px;
  margin-bottom: 0px;
}

.text-detail-dynamic {
  display: flex;
}

.content-btn-add {
  margin-right: 15px;
}

.content-btn-paid {
  margin-right: 15px;
  display: flex;
}

/*#region ESTILO CUENTA FINANCERA */
.content-icon-currency {
  align-self: center;
  align-items: center;
}

.text-icon-currency {
  color: var(--primary-color-text-black);
}

.content-account-balance {
  padding: 15px;
  display: flex;
  height: 70px;
  width: 310px;
  max-width: 310px;
  min-width: 310px;
  border: 1px solid var(--primary-color-border-input);
  background-color: var(--primary-color-menu);
  border-radius: 10px;
}

.content-account-balance:hover {
  transform: scale(1.02);
  transition: 0.2s;
  /* box-shadow: 21px 18px 50px -19px var(--primary-color-btn-primary); */

  box-shadow: -1px 13px 34px -23px var(--primary-color-btn-primary);
}

.content-text-currency {
  width: 100%;
  align-self: center;
  margin-left: 10px;
}

.text-title-account-balance {
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 13px;

  color: var(--primary-color-text);
}

.text-value-account-balance {
  font-size: 20px;
  font-family: "pop-semiBold";
  margin-bottom: 0px;
}

.class-in {
  color: var(--primary-color-text-in) !important;
}

.class-out {
  color: var(--primary-color-text-out) !important;
}

/*#endregion  ESTILO CUENTA FINANCERA  */

/* #region ESTILO DE ESTADO DE OPERACIÓN */
.content-status-operations {
  display: flex;
  align-items: center;
  margin-right: 17px;
}

.text-date-operation {
  color: var(--primary-color-text);
  font-size: 14px;
  font-family: "pop-Regular";
  /* margin-left: 10px; */
  /* margin-right: 10px; */
}

.btn-status-operation {
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  color: var(--primary-color-text-yellow) !important;
  height: 50px !important;
  border-radius: 10px !important;
  font-family: "pop-SemiBold";
  text-transform: none !important;
  letter-spacing: 0px !important;
  pointer-events: none !important;
}

.content-status-date {
  display: flex;
}

.content-text-state-date {
  margin-right: 15px;
  /* display: flex; */
  /* align-items: center; */
}

.content-info-state-date {
  display: flex;
}

/* #endregion ESTILO DE ESTADO DE OPERACIÓN */

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .text-detail-dynamic {
    display: block;
  }

  .content-header-detail-global {
    display: block;
    align-content: center;
    width: 100%;
  }

  .text-title {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 25px;
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .text-dynamic {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 25px;
    margin-bottom: 0px;
  }

  .content-btn-add {
    margin-left: 0px;
  }

  .content-btn-paid {
    margin-left: 0px;
  }

  .content-btns {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*#region ESTILO CUENTA FINANCERA */
  .content-icon-currency {
    align-self: center;
    align-items: center;
  }

  .text-icon-currency {
    color: var(--primary-color-text-black);
  }

  .content-account-balance {
    padding: 15px;
    display: flex;
    height: 70px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 1px solid var(--primary-color-border-input);
    background-color: var(--primary-color-menu);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content-account-balance:hover {
    transform: scale(1.02);
    transition: 0.5s;
    box-shadow: 21px 18px 50px -19px var(--primary-color-btn-primary);

    box-shadow: 21px 18px 50px -19px var(--primary-color-btn-primary);
  }

  .content-text-currency {
    width: 100%;
    align-self: center;
    margin-left: 10px;
  }

  .text-title-account-balance {
    margin-bottom: 0px;
    font-family: "pop-Regular";
    font-size: 13px;

    color: var(--primary-color-text);
  }

  .text-value-account-balance {
    font-size: 20px;
    font-family: "pop-semiBold";
    margin-bottom: 0px;
  }

  .class-in {
    color: var(--primary-color-text-in) !important;
  }

  .class-out {
    color: var(--primary-color-text-out) !important;
  }

  /*#endregion  ESTILO CUENTA FINANCERA  */

  /* #region ESTILO DE ESTADO DE OPERACIÓN */
  .content-status-operations {
    display: block;
    align-items: center;
    margin-right: 17px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .global-btn-header-primary {
    width: 100% !important;
  }

  .text-date-operation {
    color: var(--primary-color-text);
    font-size: 14px;
    font-family: "pop-Regular";
    /* margin-left: 10px; */
    /* margin-right: 10px; */
  }

  .btn-status-operation {
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    color: var(--primary-color-text-yellow) !important;
    height: 50px !important;
    border-radius: 10px !important;
    font-family: "pop-SemiBold";
    text-transform: none !important;
    letter-spacing: 0px !important;
    pointer-events: none !important;
  }

  /* #endregion ESTILO DE ESTADO DE OPERACIÓN */
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
